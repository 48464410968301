import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardCertificationWallOfFame from "../../common/CardCertificationWallOfFame";
import "./CardCertificationWallOfFame.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader/Loader";

const CertificationWallOfFame = ({ data, title, loading, showViewAll = true,currentQtr,currentYr,showCertificationTitle }) => {
    const certificationwalloffamedata = data;
    const hideControls = certificationwalloffamedata.length < 7
    const navigate = useNavigate();
    let certificationTitle = "Certified Employees"
    if(currentYr && currentQtr) {
        currentYr = String(currentYr).slice(2)
        certificationTitle = `FY${currentYr} Q${currentQtr} Certified Employees`;
    }
    const carouselRef = useRef(null); // Create a ref for the carousel component

    const handlePrevious = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNext = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };
    // let certificationTitle = `FY${currentYr} Q${currentQtr} Certified Employees`;
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1400 },
            items: 6,
        },
        desktop: {
            breakpoint: { max: 1400, min: 1200 },
            items: 6,
        },
        tablet: {
            breakpoint: { max: 1200, min: 991 },
            items: 5,
        },
        mobile: {
            breakpoint: { max: 991, min: 576 },
            items: 3,
        },
        xsmobile: {
            breakpoint: { max: 576, min: 0 },
            items: 2,
        },
    };

    let CertificationWallViewAll = () => {
        return navigate("/additionalresources/certificationwalloffame");
    };
    return (
        <>
            <div className="title-block p-0 pb-3">
                <span className="main-head">{title} </span>                
            </div>
            <div className={"Carousel-block mb-4 wallOfFame px-5" + (hideControls ? "hidecontrols" : "")}>
                
                {loading ? (
                    <Loader isPositionRelative={false}/>
                ) : certificationwalloffamedata?.length === 0 ? (
                    <div className="pl-30 myskilljourney no-data-found">
                        <div className="center-container">
                            <h2 className="centered-heading">No content found.</h2>
                        </div>
                    </div>
                ) : (
                    <>    
                        <div className="d-flex flex-row justify-content-between mb-3">                   
                            {showCertificationTitle && <div className="fontSize16 main-head pb-4">{certificationTitle}</div> }
                            {showViewAll ? (
                                <span className="view-all">
                                    <button className="btn btn-link btn-view" onClick={CertificationWallViewAll}>
                                        View All
                                    </button>
                                </span>
                            ) : null}</div> 
                        <div className="carousel-container">
                            <Carousel
                                responsive={responsive}
                                showDots={true}
                                slidesToSlide={6}
                                renderArrowsWhenDisabled={true}
                                keyBoardControl={true}
                                autoPlay={true}
                                autoPlaySpeed={5000}
                                infinite={true}
                                arrows={false}
                                ref={carouselRef}
                            >
                                {certificationwalloffamedata.map((certificationdata) => {
                                    return (
                                        <CardCertificationWallOfFame
                                            certificationdata={certificationdata}
                                            key={certificationdata.Sno}
                                        />
                                    );
                                })}
                            </Carousel>
                            <button className="prev-button" onClick={handlePrevious}>
                            </button>
                            <button className="next-button" onClick={handleNext}>
                            </button>
                        </div>
                    </>
                    
                )}
            </div>
        </>
    );
};

export default CertificationWallOfFame;
