import React, { useEffect,useState } from "react";
import {  Routes,Route,useLocation } from "react-router-dom";
import Profile from "./component/Profile/Profile";
import Dashboard from "./features/dashboard/Dashboard";
import Navigation from "./component/Navigation";
import { useDispatch } from "react-redux";
import { setProfile } from "./component/SignIn/userProfileSlice";
import { apis } from "./services/Api";
import CertificationWall from "./features/certificationWall/CertificationWall";
import MySkillJourney from "./features/my-skill-journey/MySkillJourney";
import CourseDetails from "../src/features/CourseDetails";
import EnablementTeam from "./features/EnablementTeam";
import Importcsv from "./component/ImportCsv/Importcsv";
import AllCourses from "./features/AllCourses/AllCourses";
import EmailTemplate from "./component/EmailTemplate/EmailTemplate";
import EditVPEmailId from "./component/EditVPEmailId/EditVPEmailId";
import MyTeamSkillsSubmission from "./features/MyTeamSkillsSubmission/MyTeamSkillsSubmission";
import RoleSkillsJourney from "./features/RoleSkillsJourney/RoleSkillsJourney";
import MyTeam from "./features/MyTeam/MyTeam";
import LearnerReports from "./features/LearnerReports/LearnerReports";
import ManageTemplate from "./features/ManageTemplates/Templates";
import CourseReports from "./features/CourseReports/CourseReports";
import MyLearningComponent from "./features/FilterSearch/MyLearningComponent";
import { setLearnerList } from "./features/MyTeam/learnerListSlice";
import SIOEnablementTeam from "./features/SIOEnablementTeam";
import NavigationSearchFilter from "./features/NavigationSearchFilter/NavigationSearchFilter";
import CoursePlayer from "./component/CoursePlayer";
import LoginFailed from "./component/LoginFailed/LoginFailed";
import ViewSkillJourney from "./features/my-skill-journey/ViewSkillJourney";
import Notifications from "./component/Notifications";
// import SkillList from "./features/RoleSkillsJourney/SkillList/SkillList";
import ViewDetail from "./features/RoleSkillsJourney/ViewDetail/ViewDetail";
import DetailsTableForLearner from "./features/LearnerReports/DetailsTableForLearner";
import DetailsTableForCourse from "./features/CourseReports/DetailsTableForCourse";
import SkillSubmissionReport from "./component/SkillSubmissionReport/SkillSubmissionReport";
import PageNotFound from "./component/PageNotFound/PageNotFound";
// const Onboarding = React.lazy(() => import("./features/Onboarding/Onboarding"))
const Menu = React.lazy(() => import("./features/CustomNavigation/Menu"))

function PrivatePath() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const shouldShowNavigation = !location.pathname.includes("/course-player");


    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const { data } = await apis.getUser();
        dispatch(setProfile(data));
        const LearnerData = await apis.getLearnersFromManager();
        // TODO - set Lerner list data by alphabetical order
        const LearnerDataWithAlphabeticalOrder = LearnerData?.sort((a, b) =>
            a?.Name?.localeCompare(b?.Name)
        );
        dispatch(setLearnerList(LearnerDataWithAlphabeticalOrder));
        setIsLoading(true);
        try {
            await apis.getLoginUserDetails().then((data) => {
                setIsAdmin(data?.result?.isAdminUser);
                setIsSuperAdmin(data?.result?.isSuperAdmin);
            });
        } catch (error) {
            console.warn(error);
        }
        setIsLoading(false);
    };

    return (
        <React.Fragment>
            {shouldShowNavigation && <Navigation />}
            <Routes>
                <Route path="/profile" element={<Profile />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/home" element={<Dashboard />} />
                <Route
                    path="/additionalresources/certificationwalloffame"
                    element={<CertificationWall />}
                />
                <Route path="/coursedetails/:course_id" element={<CourseDetails />} />
                <Route path="/coursedetails/:course_id/:instance_id" element={<CourseDetails />} />
                <Route
                    path="/coursedetails/:course_id/prerequisite/:prerequisite_id"
                    element={<CourseDetails />}
                />
                {/* <Route
                    path="/enablementTeam"
                    element={<EnablementTeam />}
                /> */}
                {/* <Route path="/editor" element={<EnablementTeam />} /> */}

                {!isLoading && (
                    <Route
                        path="/importcsv"
                        element={isAdmin || isSuperAdmin ? <Importcsv /> : <PageNotFound />}
                    />
                )}
                {!isLoading && (
                    <Route
                        path="/updatesioenablementdata"
                        element={isAdmin || isSuperAdmin ? <EnablementTeam /> : <PageNotFound />}
                    />
                )}

                <Route path="/myskilljourney" element={<MySkillJourney />} />
                <Route path="/skillssubmission" element={<MyTeamSkillsSubmission />} />
                <Route path="/myteam" element={<MyTeam />} />
                <Route path="/learnerreports" element={<LearnerReports />} />
                <Route path="/managetemplate" element={<ManageTemplate />} />
                <Route path="/coursereports" element={<CourseReports />} />
                <Route path="/allcourses" element={<AllCourses />} />
                <Route path="/mylearning" element={<MyLearningComponent />} />
                {!isLoading &&  <Route path="/emailtemplate" element={isAdmin || isSuperAdmin ? <EmailTemplate /> : <PageNotFound />} />}
                <Route path="/en/onboarding/*" element={<Menu />} />
                {/* <Route path="/onboarding/TAM" element={<Onboarding />} />
                <Route path="/onboarding/TAMManager" element={<Onboarding />} />
                <Route path="/onboarding/TAMManager/:id" element={<Onboarding />} />
                <Route path="/onboarding/TAM/:id" element={<Onboarding />} /> */}
                <Route path="/en/solutionenablement/*" element={<Menu />} />
                <Route path="/en/industryenablement/*" element={<Menu />} />
                <Route path="/en/csmethodology/*" element={<Menu />} />
                <Route path="/en/professionalsoftskills/*" element={<Menu />} />
                {/* <Route path="/solutionenablement/:id" element={<SolutionEnablement />} />
                <Route path="/solutionenablement/AEM" element={<SolutionEnablement />} /> */}
                {!isLoading && (
                    <Route
                        path="/editvpemailid"
                        element={isSuperAdmin ? <EditVPEmailId /> : <PageNotFound />}
                    />
                )}
                <Route path="/en/roleskilljourney/viewcontent" element={<ViewDetail />} />
                <Route path="/en/roleskillsjourney/*" element={<RoleSkillsJourney />} />
                <Route
                    path="/additionalresources/sioenablementteam"
                    element={<SIOEnablementTeam />}
                />
                {/* <Route path="/roleskillsjourney/:id" element={<RoleSkillsJourney />} /> */}

                <Route path="/searchcourse" element={<NavigationSearchFilter />} />
                <Route path="/course-player/:id" element={<CoursePlayer />} />
                <Route path="/loginfailed" element={<LoginFailed />} />
                <Route path="/viewskilljourney/:id" element={<ViewSkillJourney />} />
                {/* <Route path="/viewContent" element={<ViewDetail />} />                */}
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/learnerreports/learnerdetails" element={<DetailsTableForLearner />} />
                <Route path="/coursereports/coursedetails" element={<DetailsTableForCourse />} />
                {!isLoading && (
                    <Route
                        path="/skillSubmissionReport"
                        element={
                            isAdmin || isSuperAdmin ? <SkillSubmissionReport /> : <PageNotFound />
                        }
                    />
                )}
            </Routes>
            {/* <Footer></Footer> */}
        </React.Fragment>
    );
}

export default PrivatePath;
