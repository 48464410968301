import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./enablementTeam.css";
import { useTranslation } from "react-i18next";
import { apis } from "../../services/Api";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ModalforSuccessAndFailure from "../../common/Modal/Modal";
import { appContext } from "../../context/appContext";
import calendar_icon from "../../assets/images/calendar_importcsv.png";
import user_icon from "../../assets/images/user_importcsv.png";
import moment from "moment";
import { htmlEditButton } from "quill-html-edit-button";
Quill.register("modules/htmlEditButton", htmlEditButton);
const index = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState("");
    const [updatedOn,setUpdatedOn] = useState("");
    const [updatedBy,setUpdatedBy] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [messageforPopup, setMessageforPopup] = useState("");
    const [warningPopup, setWarningPopup] = useState("");
    const BlockEmbed = Quill.import("blots/block/embed");

    class IframeBlot extends BlockEmbed {
        static create(value) {
            const node = super.create();
            if (value.src) {
                value.src = convertURL(value.src);
            }
            Object.keys(value).forEach(attr => {
                node.setAttribute(attr, value[attr]);
            });
            return node;
        }
      
        static value(node) {
            const attrs = {};
            for (const { name, value } of node.attributes) {
                attrs[name] = value;
            }
            return attrs;
        }
    }

    IframeBlot.blotName = "iframe";
    IframeBlot.tagName = "iframe";
    Quill.register(IframeBlot);

    Quill.register("modules/htmlEditButton", htmlEditButton);
    const convertURL = (url) => {
        if (url.includes("youtube.com") || url.includes("youtu.be")) {
            const regExp = /(?:youtube\.com\/(?:[^#&\\?]*\/|.*\?v=)|youtu\.be\/)([^#&\\?]*).*/;
            const match = url.match(regExp);
            return match && match[1] ? `https://www.youtube.com/embed/${match[1]}` : url;
        } else if (url.includes("vimeo.com")) {
            const regExp = /(?:vimeo\.com\/(?:.*#|.*\/videos\/|video\/|.*\/)?)([0-9]+)/;
            const match = url.match(regExp);
            return match && match[1] ? `https://player.vimeo.com/video/${match[1]}` : url;
        } else {
            return url;
        }
    };

    const handleQuillChange = (value) => {
        setData(value);
    };


    const { dispatch } = useContext(appContext);

    // const handleEditor = async (e) => {
    //     const editData = e;

    //     {
    //         parse(`${editData}`);
    //     }
    //     setData(editData);
    // };
    const handlePreview = async () => {
        dispatch({ type: "editorDataforPreview", payload: data });
        navigate("/additionalresources/sioenablementteam", { state: true });
    };

    const handleCancel = async () => {
        dispatch({ type: "editorDataforPreview", payload: "" });
        navigate("/home");
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline","link"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
            ["clean"]
        ],
        htmlEditButton: {
            debug: true, 
            msg: "Edit the HTML code below",
            clipboard: {
                matchVisual: false // This ensures Quill doesn't wrap pasted content in extra tags
            }
        }
    };

    // const formats = [
    //     "header",
    //     "bold",
    //     "italic",
    //     "underline",
    //     "link",
    //     "code-block",
    //     "list",
    //     "bullet",
    //     "iframe",
    //     "image",
    //     "video"
    // ];

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    let getSIOEnablementStaticdata = async () => {
        await apis
            .getSIOEnablementStaticData()
            .then((e) => {
                let date = new Date(e.data.staticData[0].UpdatedOn);
                let formattedDate = moment(date).format("MMM Do YYYY");
                setData(e.data.staticData[0].enablementStaticData);
                setUpdatedBy(e.data.staticData[0].UpdatedBy);
                setUpdatedOn(formattedDate);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleUpdateEditor = async () => {
        if (data) {
            const result = await apis.updatSIOEnablementTeam(data);
            // const msg = result.message;
            if (!result) {
                setWarningPopup("User is not an admin.");
                setShowModal(true);
            } else {
                setMessageforPopup("SIO team data updated successfully.");
                setShowModal(true);
            }
        }
    };

    useEffect(() => {
        if (location.state) {
            setData(location.state);
        } else {
            getSIOEnablementStaticdata();
        }
    }, []);

    useEffect(() => {
        apis
            .getSIOEnablementStaticData()
            .then((e) => {
                // setData(e.data.staticData[0].enablementStaticData);
                let date = new Date(e.data.staticData[0].UpdatedOn);
                let formattedDate = moment(date).format("MMM Do YYYY");
                setUpdatedBy(e.data.staticData[0].UpdatedBy);
                setUpdatedOn(formattedDate)
            })
            .catch((error) => {
                console.error(error);
            })

    },[updatedBy,updatedOn])

    // useEffect(() => {
    //     if(location.state){
    //         setData(location.state)
    //     }
    //     // else{
    //     //     setData("")
    //     // }
    // }, [data]);

    return (
        <>
            <Container fluid className="pl-30 py-4" responsive={responsive}>
                <Row>
                    {" "}
                    <div className="headerImage">
                        {" "}
                        <h1>{t("enablement-team.meet-the-sio-enablement-team")}</h1>{" "}
                    </div>{" "}
                </Row>
            </Container>
            <Container fluid className="pl-30 py-4 show-for-manager">
                <div className="d-flex justify-content-end ">
                    <Row>
                        <Col>
                            <button className="btn primary-btn" onClick={handleCancel}>
                                {t("cancel")}
                            </button>
                        </Col>
                        <Col>
                            <button className="btn primary-btn" onClick={handlePreview}>
                                {t("preview")}
                            </button>
                        </Col>
                        <Col>
                            <button className="btn primary-btn primary-blue" onClick={handleUpdateEditor}>
                                {t("update")}
                            </button>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container fluid className="pl-30 py-4">
                <Row className="soienablementteam">
                    <Container>
                        <Row>
                            <div className="show-for-manager ">
                                <ReactQuill
                                    value={data}
                                    modules={modules}
                                    formats={null}
                                    onChange={handleQuillChange}
                                />
                                
                                <div className="footerSIO otherText pt-3">
                                  
                                    <span className="me-2"><img src={calendar_icon} alt=""></img></span>
                                    <span className="pe-3 me-3">{updatedOn}</span>
                                    <span className="me-2"><img src={user_icon} alt=""></img></span>
                                    <span className="pe-3 me-3">{updatedBy}</span>
                                 
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Row>
                <ModalforSuccessAndFailure
                    messageforPopup={messageforPopup}
                    warningPopup={warningPopup}
                    show={showModal}
                    onClose={handleCloseModal}
                />
            </Container>
        </>
    );
};
export default index;
