/* eslint-disable */
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo2 from "../../assets/images/logo_2.svg";
import logofooter from "../../assets/images/logoFooter.png";
import whiteSearch from "../../assets/images/white-search.svg";
import filter from "../../assets/images/search-filter.svg";
import graySearch from "../../assets/images/gray-search.svg";
import bell from "../../assets/images/bell.svg";
import logout_icon from "../../assets/images/log-out.svg";
import manageprofile from "../../assets/images/manage-profile.svg";
import manageTemplate from "../../assets/images/manageTemplate.svg";
import importcsv from "../../assets/images/import-csv.svg";
import skillSubmissionReport from "../../assets/images/skill-submission.svg";
import user1 from "../../assets/images/user-1.svg";
import emailicon from "../../assets/images/email-new.svg";
import emailvpicon from "../../assets/images/email-icon.svg";
import userimg from "../../assets/images/userimg.png";
import AllNotifications from "../../common/Notification/notification";
import "./navigation.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { clearLocalStorage } from "../../services/Auth";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { apis } from "../../services/Api";
import { _data, _dataApprovalPreview } from "../../data/dataMySkillJourney";
import { _dataApprovalPreviewSS, _dataSS } from "../../data/dataMyTeamSkillsSubmission";
import { imagecontent, imagecontent1, sampleProfileImg } from "../../data/dataNavigation";
import { appContext } from "../../context/appContext";
import { filterJson } from "../../constant/filterJSON";
import Breadcrumbs from "../../common/Breadcrumbs/index";
import Modal from "react-bootstrap/Modal";
import { removeDuplicateEn } from "../../helper/Helper";
import { buildNestedDataGroupedByTemplateId } from "../../utils/dataTable";
function Navigation() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        catalogListOB,
        catalogListSE,
        catalogListRSJ,
        catalogListAR,
        AvatarUrl,
        dispatch,
        notifications,loader,flag
    } = useContext(appContext);
    const [notificationData, setNotificationData] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const profile = useSelector((state) => state.userProfile);
    const navigationData = useSelector((state) => state?.navigation);
    const [userDetails, setUserDetails] = useState();
    const [showSubmenu, setShowSubmenu] = useState(false);
    const [isActiveShowSearchDiv, setIsActiveShowSearchDiv] = useState(false);
    const [isActiveHideGray, setIsActiveHideGray] = useState(true);
    const [showinput, setShowinput] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showsubMenuTitle, setShowSubMenuTitle] = useState(false);
    const [string, setString] = useState("");
    // const courseMetadataSeachFilter = ["courseName", "courseDescription", "courseOverview", "moduleName", "moduleDescription", "lpName", "lpDescription", "lpOverview", "jobAidName", "jobAidDescription", "certificationName", "certificationDescription", "certificationOverview","embedLpName","embedLpDesc","embedLpOverview"];
    const courseMetadataSeachFilter = [
        "catalogName",
        "catalogDescription",
        "courseName",
        "courseDescription",
        "courseOverview",
        "moduleName",
        "moduleDescription",
        "lpName",
        "lpDescription",
        "lpOverview",
        "jobAidName",
        "jobAidDescription",
        "certificationName",
        "certificationDescription",
        "certificationOverview"
    ];
    const noteSearchFilter = ["note"];
    const skillSearchFilter = ["skillName", "skillDescription"];
    const badgeSearchFilter = ["badgeName"];
    // const tagSearchFilter = ["courseTag", "moduleTag", "jobAidTag","certificationTag","embedLpTag"];
    const tagSearchFilter = ["courseTag", "moduleTag", "jobAidTag"];
    const initialFilters = {
        "filter.snippetTypes": [
            ...courseMetadataSeachFilter,
            ...noteSearchFilter,
            ...skillSearchFilter,
            ...badgeSearchFilter,
            ...tagSearchFilter,
            "discussion"
        ]
    };
    const [isManager, setIsManger] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [showMobileSearchBar, setShowMobileSearchBar] = useState(true);
    const [bellIconClick, setBellIconClick] = useState(false);
    let [selectedFilters, setSelectedFilters] = useState(initialFilters);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [menuopen, setMenuopen] = useState(false);
    const [displayMessageBox, setDisplayMessageBox] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [navigationJson, setNavigationJson] = useState([]);
    const getRolesAndSkillsData = async () => {
        try {
            const response = await apis.getRolesAndSkills();
            dispatch({ type: "rolesListItem", payload: response.roles });
            dispatch({ type: "skillsListItem", payload: response.skills });
            dispatch({ type: "catalogsListItem", payload: response.catalogs });
        } catch (error) {
            console.warn(error);
        }
    };
    const getNavigationDataNew = async () => {
        try {
            let response = { navData: [] };
            if (
                localStorage.getItem("NavMenuData") === null ||
                JSON.parse(localStorage.getItem("NavMenuData"))?.navData?.length === 0
            ) {
                response = await apis.getNavigationDataNew();
                localStorage.setItem("NavMenuData", JSON.stringify(response));
            } else {
                response = JSON.parse(localStorage.getItem("NavMenuData"));
            }
            dispatch({ type: "navigationNewData", payload: response.navData });
            setNavigationJson(response);
        } catch (error) {
            console.warn(error);
        }
    };
    const getJSONNavigationData = async () => {
        try {
            let response = { JSONData: [] };
            if (
                localStorage.getItem("TemplateJSONData") === null ||
              JSON.parse(localStorage.getItem("TemplateJSONData"))?.JSONData?.length === 0
            ) {
                const flatData = await apis.getNavigationFlatData();
                // console.log("flatData >>>", flatData.message)
                response = await buildNestedDataGroupedByTemplateId(flatData);
                 // Ensure the data is in the correct format
              const convertedData = { JSONData: response };
                localStorage.setItem("TemplateJSONData", JSON.stringify(convertedData));
            } else {
                response = JSON.parse(localStorage.getItem("TemplateJSONData"));
            }
            dispatch({ type: "templateJSONData", payload: response.JSONData });
            setNavigationJson(response);
        } catch (error) {
            console.warn(error);
        }
    };
    const onChangeFilterSnippetTypes = (value, e) => {
        const isCourseMetadataChecked = document.getElementById("checkcourseName").checked;
        const isNoteChecked = document.getElementById("checknote").checked;
        const isBadgeChecked = document.getElementById("checkbadgeName").checked;
        const isSkillsChecked = document.getElementById("checkskillName").checked;
        const isTagChecked = document.getElementById("checkcourseTag").checked;
        let filter = ["discussion"];
        if (isCourseMetadataChecked) filter = [...filter, ...courseMetadataSeachFilter];

        if (isNoteChecked) filter = [...filter, ...noteSearchFilter];

        if (isBadgeChecked) filter = [...filter, ...badgeSearchFilter];

        if (isSkillsChecked) filter = [...filter, ...skillSearchFilter];

        if (isTagChecked) filter = [...filter, ...tagSearchFilter];

        setSelectedFilters({ "filter.snippetTypes": filter });
    };
    let navigationArr = [];

    const callToAdobeAnalytics = () => {
        try {
            _satellite.track("pagechange");
        }
        catch(error) {
            console.error("Error in callToAdobeAnalytics: ", error);
        }
    }

    const navigateTo = (e) => {
        e.preventDefault();
        const anchor = e?.target?.closest("a");
        const href = anchor?.getAttribute("href");
        const absoluteUrl = href ? href.replace("#", "") : "/"; // Make the URL absolute
        navigate(absoluteUrl, {
            state: { id: 7, isMyLearning: true }
        });
        callToAdobeAnalytics();
    };

    const navigateToSIO = () => {
        e.preventDefault();
        navigate(`/updatesioenablementdata`);
        callToAdobeAnalytics();
    };

    const navigateById = (tabId, item, root, breadCrumb) => {
        // const path = `/${tabId}#${tabId}`;
        const path = `/${tabId}`;
        if(path === "/additionalresources/sioenablementteam"){
            dispatch({ type: "editorDataforPreview", payload: "" });
        }
        navigate(path, { state: { id: item?.catalogId, item, root, breadCrumb } });
        callToAdobeAnalytics();
    };

    const navigateById2 = (tabId, item) => {
        const path = `/${tabId}`;
        navigate(path, { state: { id: item.catalogid, item } });
    };

    const logout = () => {
        clearLocalStorage();
        navigate("/login");
    };
    const setSubMenuTitleOnMouseEnter = () => {
        setShowSubMenuTitle(true);
    };
    const setSubMenuTitleOnMouseLeave = () => {
        setShowSubMenuTitle(false);
    };

    const setStaticData = () => {
        dispatch({ type: "mySkillJourneyDataItem", payload: _data });
        dispatch({ type: "skillApprovalPreviewDataItem", payload: _dataApprovalPreview });
        dispatch({ type: "myTeamSkillSubmissionDataItem", payload: _dataSS });
        dispatch({ type: "myTeamApprovalPreviewDataItem", payload: _dataApprovalPreviewSS });
    };

    useEffect(() => {
        getJSONNavigationData();
      }, []);

    useEffect(() => {
        setStaticData();
        apis.getLoginUserDetails().then((data) => {
            dispatch({ type: "loginUser", payload: data });
            setIsAdmin(data?.result?.isAdminUser);
            setIsSuperAdmin(data?.result?.isSuperAdmin);
        });
    }, []);

    useEffect(() => {
        setUserDetails(profile?.userProfile);
        setOptionsPerTab();
        setShowSubmenu(false);
    }, [profile]);

    useEffect(() => {
        setStaticData();
        getRolesAndSkillsData();
    }, []);

    useEffect(() => {
                if (navigationData) {
            setOptionsPerTab(navigationData);
        }
    }, [navigationData]);

    const reorganizeDropdownItems = (list) => {
        let isNotComingSoomList = list.filter((item) => item.isComingSoon !== true);
        let isComingSoomList = list.filter((item) => item.isComingSoon === true);
        return [...isNotComingSoomList, ...isComingSoomList];
    };

    const setOptionsPerTab = async (navigationData) => {
        const _navData = navigationData?.navigation;
        if (_navData) {
            dispatch({
                type: "catalogItemsOB",
                payload: reorganizeDropdownItems(_navData["Onboarding"])
            });
            dispatch({
                type: "catalogItemsSE",
                payload: reorganizeDropdownItems(_navData["Solution Enablement"])
            });
            dispatch({
                type: "catalogItemsRSJ",
                payload: reorganizeDropdownItems(_navData["Skills"])
            });
            dispatch({ type: "catalogItemsAR", payload: _navData["Additional Resources"] });
        }
    };

    const seacrhBarClass = () => {
        setIsActiveShowSearchDiv(true);
        setIsActiveHideGray(false);
        setShowMobileSearchBar(!showMobileSearchBar);
        setIsOpen(true);
    };

    async function handleSearch(e) {
        setString(e.target.value);
        let enteredValue = e.target.value;
        if (!string && showinput === "dropdown") {
            setShowinput("input");
        } else if (string && showinput !== "dropdown") {
            setShowinput("dropdown");
        }
        try {
            // const data = await apis.searchSuggestions(enteredValue);
            // data && setSearchResults(data.data);
        } catch (error) {
            setSearchResults([]);
        }
    }

    const goToCourseDetails = (e) => {
        if (string !== "") {
            setShowFilterOptions(false);
            let filterSnippetTypesArry = [...new Set(selectedFilters["filter.snippetTypes"])];
            let search = "";
            if (filterSnippetTypesArry.length > 0) {
                search = `?q=${string}&filter.snippetTypes=${filterSnippetTypesArry.join(",")}`;
            } else {
                search = `?q=${string}`;
            }
            window.scrollTo(0, 0);
            navigate({
                pathname: `/searchcourse`,
                search: search,
                state: {
                    searchstr: search
                }
            });
            setShowinput("");
            setString("");
            dispatch({ type: "searchCourseParamItem", payload: filterSnippetTypesArry });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            goToCourseDetails();
        }
    };

    const onClickFilter = (e) => {
        setShowFilterOptions(!showFilterOptions);
    };

    const handleToggleClick = () => {
        setMenuopen((prev) => !prev);
    };
    const handleCloseDisplayMessage = () => {
        setDisplayMessageBox(false);
        setBellIconClick(true);
    };
    const fetchNotifications = async () => {
        try {
            let response = await apis.getNotifications();
            let filteredresponse = response?.filter(
                (item) => !item.NotificationMessage.includes("DNS")
            );
            setNotificationData(filteredresponse);
            let filteRedresponseCount = filteredresponse?.filter((item) => item?.read === false);
            setNotificationCount(filteRedresponseCount.length);
            dispatch({ type: "notifications", payload: filteredresponse });
        } catch (error) {
            console.error(error);
        }
    };
    let bellIconClickActivity = async () => {
        setBellIconClick(true);
        setDisplayMessageBox(true);
        setNotificationCount(0);
        const postData = notificationData
            ?.filter((item) => !item.NotificationMessage.includes("DNS"))
            ?.map(({ id, type }) => ({ id, type }));
        let markReadNotification = await apis.markReadNotifications(postData);
    };
    let hideModalActivity = () => {
        setBellIconClick(false);
        setDisplayMessageBox(false);
    };
    const navigateToNotifications = () => {
        navigate("/notifications");
        setBellIconClick(false);
        setDisplayMessageBox(false);
    };

    useEffect(() => {
        setUserDetails(profile?.userProfile);
        setIsManger(profile?.userProfile?.attributes.roles.indexOf("Manager") > -1 ? true : false);
        setShowSubmenu(false);
    }, [profile]);

    useEffect(() => {
        function handleClickOutsideSearchFilter(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowFilterOptions(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutsideSearchFilter);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideSearchFilter);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    for (var key in navigationJson?.navData) {
        navigationArr.push({
            menuName: key,
            menuValue: navigationJson.navData[key]
        });
    }

    useEffect(() => {
        // Fetch notifications on component mount
        fetchNotifications();
        // Set up a 3-minutes interval to refetch notifications
        const intervalId = setInterval(fetchNotifications, 180000);
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        getNavigationDataNew();
    }, []);

    
    const generateRecursiveMenuStructure = (navData, subNavData, index, urlPath, breadCrumb) => {
            if (subNavData?.SubMenuItems && subNavData?.SubMenuItems.length > 0) {
            const tempBreadCrumb = JSON.parse(JSON.stringify(breadCrumb));
            tempBreadCrumb.push(subNavData?.MenuTitle);
            const pathId = removeDuplicateEn(`en/${urlPath}/${subNavData?.MenuIdentifier}`);
            return (
                <NavDropdown
                    title={subNavData?.MenuTitle}
                    className="supportMenu support-engg dropdown-item dropdown-show-second">
                    {subNavData?.SubMenuItems.map((data, i) => {
                        return generateRecursiveMenuStructure(
                            navData,
                            data,
                            i,
                            pathId,
                            tempBreadCrumb
                        );
                    })}
                </NavDropdown>
            );
        } else if (subNavData?.Link.trim() === "") {
            const pathId = removeDuplicateEn(`en/${urlPath}/${subNavData?.MenuIdentifier}`);
            
            const tempBreadCrumb = JSON.parse(JSON.stringify(breadCrumb));
            tempBreadCrumb.push(subNavData?.MenuTitle);
            return (
                <NavDropdown.Item
                    key={`${navData?.MenuIdentifier}-${index}`}
                    onClick={(e) => {
                        if(!(location.pathname === `/${pathId}`)){
                            dispatch({ type: "loader", payload: true });
                            dispatch({ type: "flag", payload: false });
                        }
                        e.preventDefault();
                        if (!subNavData?.IsComingSoon) {
                            navigateById(pathId, subNavData, navData, tempBreadCrumb);
                        }
                    }}
                    href={`/${pathId}`}
                    style={{
                        pointerEvents: subNavData.IsComingSoon ? "none" : "unset"
                    }}>
                    <div className="item-name">{subNavData.MenuTitle}</div>
                    {subNavData.IsComingSoon ? (
                        <div className="item-name coming-soon">Coming Soon</div>
                    ) : null}
                </NavDropdown.Item>
            );
        } else {
            return (
                <NavDropdown.Item
                    key={`${navData?.MenuIdentifier}-${index}`}
                    href={subNavData?.Link.trim()}
                    target="_blank"
                    className="item-name">
                    {subNavData?.MenuTitle}
                </NavDropdown.Item>
            );
        }
    };

    const profileImage = useMemo(() => {
        return userDetails?.attributes?.avatarUrl === imagecontent ||
            userDetails?.attributes?.avatarUrl === imagecontent1
            ? sampleProfileImg
            : AvatarUrl?.avatarUrl
            ? AvatarUrl?.avatarUrl
            : userDetails?.attributes?.avatarUrl;
    }, [userDetails, sampleProfileImg, AvatarUrl, imagecontent, imagecontent1]);

    return (
        <React.Fragment>
            <div className="sticky-top">
                <Navbar bg="light">
                    <Container fluid>
                        <Link to="/" className="navbar-brand logoMobile">
                            <img src={logo2} className="" alt="" />
                        </Link>

                        <Nav className="navbar-one">
                            <div
                                href="#"
                                className="search-input-nav"
                                onClick={seacrhBarClass}
                                ref={dropdownRef}>
                                <img
                                    alt=""
                                    src={graySearch}
                                    className={"searchIcon hideGray gray"}
                                />
                                <div className={"searchDiv showSearchDiv"}>
                                    <input
                                        type="search"
                                        className="search-input"
                                        onChange={handleSearch}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Search for keyword"
                                        value={string}
                                    />
                                    <img
                                        alt=""
                                        src={whiteSearch}
                                        className="searchIcon white"
                                        onClick={goToCourseDetails}
                                    />
                                    <img
                                        alt=""
                                        src={filter}
                                        className="searchIcon filter"
                                        onClick={onClickFilter}
                                    />

                                    {showFilterOptions && (
                                        <div className="scroller searchBoxUp">
                                            <p className="mb-1 textSearch">Search in</p>
                                            {filterJson.snippetTypes.map((element) => {
                                                return (
                                                    <div key={element?.id}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="Search In"
                                                            value={element?.value}
                                                            id={`check${element?.value}`}
                                                            label={<span>{element?.label}</span>}
                                                            onChange={(e) =>
                                                                onChangeFilterSnippetTypes(
                                                                    element.value,
                                                                    e
                                                                )
                                                            }
                                                            checked={selectedFilters[
                                                                "filter.snippetTypes"
                                                            ]?.includes(element.value)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Nav.Link
                                onClick={(e) => navigateTo(e)}
                                href="/allcourses"
                                className="px-3 right-bar navLine d-none d-md-none d-lg-block">
                                {t("all-courses-label")}
                            </Nav.Link>

                            <Nav.Link
                                onClick={(e) => navigateTo(e)}
                                href="/mylearning"
                                className="px-3 right-bar navLine d-none d-md-none d-lg-block">
                                {t("my-learning-label")}
                            </Nav.Link>
                            <Nav.Link
                                onClick={(e) => navigateTo(e)}
                                href="/myskilljourney"
                                className="px-3 right-bar navLine d-none d-md-none d-lg-block pe-4">
                                {t("my-skill-journey-label")}
                            </Nav.Link>
                            {isManager && (
                                <NavDropdown
                                    title={t("my-team-top-label")}
                                    id="myTeamDropDown"
                                    className="px-3 right-bar d-none d-md-none d-lg-block my-team-nav">
                                    <NavDropdown.Item
                                        onClick={(e) => navigateTo(e)}
                                        href="/learnerreports">
                                        {t("learner-reports-label")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={(e) => navigateTo(e)}
                                        href="/coursereports">
                                        {t("course-reports-label")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={(e) => navigateTo(e)} href="/myteam">
                                        {t("my-team-label")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={(e) => navigateTo(e)}
                                        href="/skillssubmission">
                                        {t("skills-submission-label")}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                            <Nav.Link href="#" className="px-3 position-relative mx-3">
                                <img alt="" src={bell} onClick={bellIconClickActivity}></img>
                                <span
                                    onClick={bellIconClickActivity}
                                    className="position-absolute notification-cnt">
                                    {notificationCount}
                                </span>
                            </Nav.Link>

                            <img alt="" className="user-profile-pic" src={profileImage}></img>
                            <NavDropdown
                                title=""
                                id="navbarScrollingDropdown"
                                className="mt-2 mb-5 profile-menu">
                                <NavDropdown.Item
                                    onClick={(e) => navigateTo(e)}
                                    href="/profile"
                                    //style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                                >
                                    {userDetails?.attributes?.name}
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={(e) => navigateTo(e)}
                                    href="/profile"
                                    className="px-3 py-2">
                                    <img
                                        src={manageprofile}
                                        className="px-1 mb-1 profileNavMenu"
                                        alt=""></img>{" "}
                                    {t("profile-setting")}
                                </NavDropdown.Item>
                                
                                {(isAdmin || isSuperAdmin) && (
                                    <React.Fragment>
                                        <NavDropdown.Item
                                            onClick={(e) => navigateTo(e)}
                                            href="/importcsv"
                                            className="px-3 py-2">
                                            <img
                                                src={importcsv}
                                                className="px-1 mb-1 profileNavMenu"
                                                alt=""></img>{" "}
                                            {t("import-csv")}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                    onClick={(e) => navigateTo(e)}
                                    href="/managetemplate"
                                    className="px-3 py-2">
                                    <img
                                        src={manageTemplate}
                                        className="px-1 mb-1 manageTemplateIcon"
                                        alt=""></img>{" "}
                                    {t("manage-templates")}
                                </NavDropdown.Item>
                                        <NavDropdown.Item
                                            onClick={(e) => navigateTo(e)}
                                            href="/skillSubmissionReport"
                                            className="px-3 py-2">
                                            <img
                                                src={skillSubmissionReport}
                                                className="px-1 mb-1 profileNavMenu"
                                                alt=""></img>{" "}
                                            {t("skill-submission-report")}
                                        </NavDropdown.Item>
                                        {isSuperAdmin && (
                                            <NavDropdown.Item
                                                onClick={navigateTo}
                                                href="/editvpemailid"
                                                className="px-3 py-2">
                                                <img
                                                    src={emailicon}
                                                    className="px-1 mb-1 profileNavMenu"
                                                    alt=""></img>{" "}
                                                {t("edit-vp-email-id")}
                                            </NavDropdown.Item>
                                        )}

                                        <NavDropdown.Item
                                            onClick={navigateTo}
                                            href="/emailtemplate"
                                            className="px-3 py-2">
                                            <img
                                                src={emailvpicon}
                                                className="px-1 mb-1 profileNavMenu"
                                                alt=""></img>{" "}
                                            {t("email-template")}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            onClick={navigateToSIO}
                                            href="/updatesioenablementdata"
                                            className="px-3 py-2">
                                            <img
                                                src={user1}
                                                className="px-1 mb-1 profileNavMenu"
                                                alt=""></img>{" "}
                                            {"Meet the SIO Enablement Team"}
                                        </NavDropdown.Item>
                                    </React.Fragment>
                                )}
                                <NavDropdown.Item
                                    onClick={logout}
                                    href="/login"
                                    className="px-3 py-2">
                                    <img src={logout_icon} className="px-1 mb-1" alt=""></img>{" "}
                                    {t("logout")}
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Container>
                </Navbar>

                <Navbar bg="light" sticky="top" expand="lg" className={menuopen ? "menuOpen" : ""}>
                    <Container fluid>
                        <Navbar.Toggle
                            aria-controls="navbarScroll"
                            onClick={() => handleToggleClick()}
                        />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="me-auto my-4 my-lg-0 navbar-two w-100 nav-mobile">
                                <Nav.Link
                                    onClick={(e) => navigateTo(e)}
                                    href="/allcourses"
                                    className="d-lg-none mt-3">
                                    {t("all-courses-label")}
                                </Nav.Link>

                                <Nav.Link
                                    onClick={(e) => navigateTo(e)}
                                    href="/myskilljourney"
                                    className="d-lg-none">
                                    {t("my-skill-journey-label")}
                                </Nav.Link>

                                <Nav.Link
                                    href="/mylearning"
                                    className="d-lg-none"
                                    onClick={(e) => navigateTo(e)}>
                                    {t("my-learning-label")}
                                </Nav.Link>
                                {(isManager || isAdmin || isSuperAdmin) && (
                                    <NavDropdown
                                        title={t("my-team-top-label")}
                                        id="myTeamDropDown"
                                        className="right-bar d-md-block d-lg-none my-team-nav px-0 mb-4 divider-strip">
                                        <NavDropdown.Item
                                            onClick={(e) => navigateTo(e)}
                                            href="/learnerreports">
                                            {t("learner-reports-label")}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            onClick={(e) => navigateTo(e)}
                                            href="/coursereports">
                                            {t("course-reports-label")}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            onClick={(e) => navigateTo(e)}
                                            href="/myteam">
                                            {t("my-team-label")}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            onClick={(e) => navigateTo(e)}
                                            href="/skillssubmission">
                                            {t("skills-submission-label")}
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                )}

                                <Nav.Link
                                    active={["/", "/home"].includes(window.location.pathname)}
                                    onClick={(e) => navigateTo(e)}
                                    className="me-auto desktop-home"
                                    href="/home">
                                    {t("nav.home")}
                                </Nav.Link>
                                {
                                    //navigationArr.map((navData,index)=>{
                                    navigationJson?.navData?.map((navData, index) => {
                                        if (
                                            navData?.SubMenuItems &&
                                            navData?.SubMenuItems?.length > 0
                                        ) {
                                            const urlPath =
                                                navData?.TemplateId.toLowerCase().split(
                                                    "template_"
                                                )[1];
                                            const breadCrumb = [navData?.MenuTitle];

                                            return (
                                                <NavDropdown
                                                    title={navData?.MenuTitle}
                                                    id={`menu-${index}`}
                                                    className="me-auto px-0">
                                                    {navData?.SubMenuItems?.map(
                                                        (subNavData, index) => {
                                                            return generateRecursiveMenuStructure(
                                                                navData,
                                                                subNavData,
                                                                index,
                                                                urlPath,
                                                                breadCrumb
                                                            );
                                                        }
                                                    )}
                                                </NavDropdown>
                                            );
                                        } else {
                                            return (
                                                <Nav.Link
                                                    active={
                                                        window.location.pathname ===
                                                        `/${
                                                            navData?.TemplateId?.toLowerCase().split(
                                                                "template_"
                                                            )[1]
                                                        }`
                                                    }
                                                    onClick={(e) => navigateTo(e)}
                                                    href={
                                                        navData?.TemplateId?.toLowerCase().split(
                                                            "template_"
                                                        )[1]
                                                    }
                                                    className="me-auto">
                                                    {" "}
                                                    {navData?.MenuTitle}{" "}
                                                </Nav.Link>
                                            );
                                        }
                                    })
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div
                    className={
                        showMobileSearchBar
                            ? "searchDiv mobileSearchDiv pl-30"
                            : "searchDiv showMobileSearchDiv mobileSearchDiv px-5"
                    }>
                    <div className="mb-1 ">
                        <input
                            type="search"
                            className="search-input"
                            placeholder="Search for keyword"
                            onChange={handleSearch}
                            onKeyDown={handleKeyDown}
                        />
                        <img
                            alt=""
                            src={whiteSearch}
                            className="nav-search-icon white"
                            onClick={goToCourseDetails}
                        />

                        <img
                            alt=""
                            src={filter}
                            className="searchIcon filter"
                            onClick={onClickFilter}
                        />
                    </div>

                    {showFilterOptions && (
                        <ul className="searchResult">
                            <p>Search in</p>
                            {filterJson.snippetTypes.map((element) => {
                                return (
                                    <li key={element?.id}>
                                        <Form.Check
                                            type="checkbox"
                                            name="Search In"
                                            value={element?.value}
                                            id={`check${element?.value}`}
                                            label={<span>{element?.label}</span>}
                                            onChange={(e) =>
                                                onChangeFilterSnippetTypes(element.value, e)
                                            }
                                            checked={selectedFilters[
                                                "filter.snippetTypes"
                                            ]?.includes(element.value)}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
                <Breadcrumbs />
            </div>
            {/* <Navbar bg="light" fixed="bottom" className="navbar-footer navigation">
        <Container fluid className="home-footer">
          <Navbar.Brand className="m-0 ps-2">
            <img src={logofooter} className="logoFooter" alt="" />
          </Navbar.Brand>

          <Nav className="justify-content-end">
            <Nav.Link className="p-0">
              Copyright © 2023 Adobe. All rights reserved.
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar> */}
      {( 
        <div>
            {bellIconClick && (
                <Modal
                    show={displayMessageBox}
                    className="notificationModal"
                    onHide={hideModalActivity}>
                    <Modal.Header className="pb-3 p-4">
                        <Modal.Title className="modalTitle">Notifications</Modal.Title>
                        <div className="d-flex align-items-center">
                            <button
                                variant="default"
                                className="btn showAll"
                                onClick={navigateToNotifications}>
                                Show All
                            </button>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handleCloseDisplayMessage}></button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="p-4 scroller">
                        <AllNotifications />
                    </Modal.Body>
                </Modal>
            )}
        </div>
        )}
        </React.Fragment>
    );
}

export default Navigation;
